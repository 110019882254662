function compare(a, b) {
  let equal = true;
  [...Object.keys(a), ...Object.keys(b)].forEach(k => {
    if (a[k] !== b[k]) {
      equal = typeof a[k] === 'object' && typeof b[k] === 'object' ? compare(a[k], b[k]) : false;
    }
  });
  return equal;
};

// test: console.log('compare', compare({ a: { b: 2 } }, { a: { b: 2 } }));

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

function translate(langPack, lang) {
  return (text) => langPack[text] && langPack[text][lang] ? langPack[text][lang] : text;
}

function scrollTop() {
  window.scrollTo({ top: 0, behavior: 'smooth' });
}

function cls(...args) {
  return args.filter(c => !!c).join(' ');
}

function dataURItoObjectURL(dataURI) {
  const [desc, data] = dataURI.split(',');
  const mime = desc.split(':')[1].split(';')[0];
  const binary = atob(data);
  const array = [];
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  const blob = new Blob([new Uint8Array(array)], { type: mime });
  return URL.createObjectURL(blob);
}

function randomHlsColor(saturation = 100, lightness = 50) {
  return `hsl(${Math.random() * 360}, ${saturation}%, ${lightness}%)`;
}

export {
  cls,
  compare,
  translate,
  scrollTop,
  shuffleArray,
  randomHlsColor,
  dataURItoObjectURL,
};
