import { useEffect, useState } from 'react';

import { BASE_PATH } from '../../../constants';

import { dataURItoObjectURL } from '../../../helper';



export const placeholderImage = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=';

const placeholderObjectURL = dataURItoObjectURL(placeholderImage);

export const imageSrc = (path, placeholder) => {
  return path ? (path.startsWith('http') ? path : `${BASE_PATH}/${path}`)
    : (placeholder || placeholderObjectURL);
};

const Image = ({ src, alt = '', placeholder, ...rest }) => {
  const [path, setPath] = useState(placeholderObjectURL);

  const onError = () => {
    setPath(placeholder);
  };

  useEffect(() => {
    const image = imageSrc(src, placeholder);
    setPath(image);
  }, [src, placeholder]);

  return <img src={path} onError={onError} alt={alt} {...rest} />;
}

export default Image;
