import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { currency } from '../../../constants';

import { cls, scrollTop } from '../../../helper';

import {
  save,
  close,
  restore,
  removeItem,
  changeItemCount
} from '../../../state/cartSlice';

import { PRODUCT_PLACEHOLDER } from '../../layouts/Products/Products';

import Image from '../../elements/Image/Image';

import { Icon } from '../../../icons/Icon';
import { CartIcon } from '../../../icons/CartIcon';

import './Cart.css';



export function CartItem({ item, index }) {
  const { product, options, variants, quantity, price } = item;

  const content = product.Content ? product.Content.replaceAll(',',', ').trim() : '';

  const total = price * quantity;

  const variantKeys = Object.keys(variants);

  // const [showMore, setShowMore] = useState(false);

  const dispatch = useDispatch();

  const removeCartItem = () => {
    dispatch(removeItem(index));
    dispatch(save());
  };

  return (
    <div key={item.id} className="CartItem flex flex-gap">
      <div className="flex-unshrink">
        <Image className="CartItem-image" src={product.Image} placeholder={PRODUCT_PLACEHOLDER} />
      </div>
      <div className="CartItem-info flex-grow flex flex-column">
        <div className="flex flex-gap justify-between">
          <div className="CartItem-title">
            {product.Title}
          </div>
          <button
            type="button"
            className="CartItem-remove"
            onClick={removeCartItem}
          >
            &times;
          </button>
        </div>
        <div className="CartItem-info flex flex-column flex-shrink">
          {content && (
            <div className="CartItem-content">
              {content}
            </div>
          )}
          {variantKeys.length > 0 && (
            <div className="CartItem-variants">
              {variantKeys.map(prop => (
                <div key={prop}>
                  <span>{prop}:</span> {variants[prop]}
                </div>
              ))}
            </div>
          )}
          {options.length > 0 && (
            <div className="CartItem-options">
              {options.map(o => (
                <span key={o.option.ID}>{o.option.Title} &times; {o.quantity}</span>
              ))}
            </div>
          )}
        </div>
        <div className="flex flex-gap justify-between align-center">
          <div className="CartItem-price">
            <span className="Price">{Math.round(total * 100) / 100} {currency}</span>
          </div>
          <div className="CartItem-quantity">
            <button
              type="button"
              disabled={quantity === 1}
              className="ProductQuantityButton"
              onClick={() => {
                dispatch(changeItemCount({ ...item, quantity: quantity - 1 }));
                dispatch(save());
              }}
            >
              -
            </button>
            <span className="ProductQuantityNumber">{quantity}</span>
            <button
              type="button"
              className="ProductQuantityButton"
              onClick={() => {
                dispatch(changeItemCount({ ...item, quantity: quantity + 1 }));
                dispatch(save());
              }}
            >
              +
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function Cart() {
  const { items, opened, quantity, total } = useSelector(state => state.cart);

  const dispatch = useDispatch();

  const cartCloseRef = useRef(null);

  const timeoutRef = useRef(null);

  const [show, setShow] = useState(opened);

  const cartClasses = cls(
    'Cart',
    opened ? 'opened' : 'closed',
  );

  const overlayClasses = cls(
    'Cart-overlay',
    opened && show && 'opened',
  );

  const onOrderClick = () => {
    scrollTop();
    dispatch(close());
  };
  
  useEffect(() => {
    if (opened) {
      setShow(true);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    } else {
      timeoutRef.current = setTimeout(() => {
        setShow(false);
      }, 300);
    }
    if (opened && cartCloseRef.current) {
      setTimeout(() => cartCloseRef.current.focus(), 100);
    }
  }, [opened]);

  useEffect(() => {
    dispatch(restore());
  }, []);

  return (<>
    {(opened || show) && (
      <div className={overlayClasses} onClick={() => dispatch(close())}></div>
    )}
    <div className={cartClasses}>
      <div className="Cart-title">Carrinho</div>
      <button
        type="button"
        ref={cartCloseRef}
        className="Cart-close"
        onClick={() => dispatch(close())}
        title="Fechar carrinho"
      >
        <span>+</span>
      </button>
      <div className="Cart-list">
        {items.length > 0 ? (
          <div className="flex flex-column flex-gap-half">
            {items.map((item, index) => (
              <CartItem key={index} item={item} index={index} />
            ))}
          </div>
        ) : (
          <div className="Cart-empty flex flex-column flex-gap align-center">
            <Icon className="Cart-empty-icon">
              <CartIcon />
            </Icon>
            <div className="Cart-empty-text">
              Carrinho vazio
            </div>
            <Link
              to={`/menu`}
              className="BigButton"
              title="Ir para o menu"
              onClick={() => dispatch(close())}
            >
              Ir para o menu <span>&rarr;</span>
            </Link>
          </div>
        )}
      </div>
      {quantity > 0 && (
        <div className="Cart-summary flex flex-wrap flex-gap justify-between align-center">
          <div>
            <div className="Cart-products-count">
              <span>Produtos:</span> {quantity}
            </div>
            <div className="Cart-total">
              Preço total: <span>
                {Math.round(total * 100) / 100} {currency}
              </span>
            </div>
          </div>
          <div>
            <Link className="button Cart-OrderButton" to="/order" onClick={onOrderClick}>
              Pedido
            </Link>
          </div>
        </div>
      )}
    </div>
  </>);
}

export default Cart;
