import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { listGallery } from '../../../requests';

import { add as addMessage } from '../../../state/notifySlice';

import Container from '../../elements/Container/Container';
import Fetching from '../../elements/Fetching/Fetching';
import Image from '../../elements/Image/Image';

import './Gallery.css';



const GalleryItem = ({ path, index }) => (
  <Image className="appear-seq" style={{ '--animation-order': index }} src={path} />
);

function Gallery() {
  const [fetching, setFetching] = useState(false);
  const [items, setItems] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    let disposed = false;
    setFetching(true);
    listGallery()
      .then(({ data }) => { if (!disposed) setItems(data || []); })
      .catch(error => {
        console.error(error);
        if (!disposed) dispatch(addMessage({
          type: 'error',
          text: 'Erro ao carregar "gallery"'
        }));
      })
      .finally(() => { if (!disposed) setFetching(false); });
    return () => disposed = true;
  }, [dispatch]);

  return (
    <div className="space-two">
      <Container>
        {fetching ? <Fetching /> : (
          <div className="Gallery">
            <div className="flex flex-gap flex-column">
              {items.map((item, i) => <GalleryItem key={item} path={item} index={i} />)}
            </div>
          </div>
        )}
      </Container>
    </div>
  );
}

export default Gallery;
