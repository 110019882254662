import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { scrollTop, shuffleArray } from '../../../helper';

import Container from '../../elements/Container/Container';
import Fetching from '../../elements/Fetching/Fetching';
import Section from '../../elements/Section/Section';
import Image, { imageSrc } from '../../elements/Image/Image';

import { ProductsList } from '../Products/Products';
import Promos from '../Promos/Promos';

import './Home.css';



function Background() {
  return (
    <div className="ImageCanvas">
      <div className="ImageOrigin FlyingPizza">
        <img src="/images/new/flying-pizza.webp" alt="" />
      </div>
      <div className="ImageOrigin FlyingBaz1">
        <img src="/images/new/flying-baz-1.webp" alt="" loading="lazy" />
      </div>
      <div className="ImageOrigin FlyingBaz2">
        <img src="/images/new/flying-baz-2.webp" alt="" loading="lazy" />
      </div>
      <div className="ImageOrigin FlyingBaz3">
        <img src="/images/new/flying-baz-3.webp" alt="" loading="lazy" />
      </div>
      <div className="ImageOrigin FlyingBaz4">
        <img src="/images/new/flying-baz-4.webp" alt="" loading="lazy" />
      </div>
      <div className="ImageOrigin FlyingBaz5">
        <img src="/images/new/flying-baz-4.webp" alt="" loading="lazy" />
      </div>
      <div className="ImageOrigin FlyingBaz6">
        <img src="/images/new/flying-baz-4.webp" alt="" loading="lazy" />
      </div>
    </div>
  );
}

function About() {
  return (
    <div className="About">
      <Section name="Home - Main Text" skeleton={<Fetching />} />
      <Link
        to={`/menu`}
        className="BigButton"
        title="Faça uma encomenda"
      >
        Faça uma encomenda <span>&rarr;</span>
      </Link>
    </div>
  );
}

function ProductsMenuItem({ item, index }) {
  const bgStyle = { backgroundImage: `url(${imageSrc(item.Image)})` };

  return (
    <div className="appear-seq" style={{ '--animation-order': index }}>
      <Link className="ProductsMenu-item" to={`/menu/${item.ID}`} onClick={scrollTop}>
        <span className="ProductsMenu-item-image-shadow" style={bgStyle} /> 
        <Image src={item.Image} className="ProductsMenu-item-image" />
        <span className="ProductsMenu-item-title">{item.Title}</span>
      </Link>
    </div>
  );
}

function ProductsMenu() {
  const { categories: cats, fetching } = useSelector(state => state.categories);

  return (
    <div className="ProductsMenu">
      {!cats || cats.length === 0
        ? (fetching ? <Fetching /> : '')
        : cats.map((item, i) => (
          <ProductsMenuItem key={item.ID} item={item} index={i} />
        ))}
    </div>
  );
}

function Welcome() {
  return (
    <section className="Welcome">
      <Container>
        <Background />
        <About />
        <ProductsMenu />
      </Container>
    </section>
  );
}

function PopularList({ items }) {
  return items.length > 0 && (
    <div className="space-two">
      <ProductsList items={items} /> 
    </div>
  );
}

function Popular() {
  const { items, fetching } = useSelector(state => state.products);

  // TODO: splice -> filter by unique category
  const popular = shuffleArray([...items]).splice(0, 4);

  return (
    <section className="space-two">
      <Container>
        <div className="PopularTitle text-center">Promoções</div>
        {fetching && items.length === 0 ? <Fetching /> : <PopularList items={popular} />}
        <div className="text-center">
          <Link
            to={`/menu`}
            className="BigButton"
          >
            Ver todas <span>&rarr;</span>
          </Link>
        </div>
      </Container>
    </section>
  );
}

function Home() {
  return (
    <div className="Home fade-in">
      <Welcome />
      <div className="relative bg-default">
        <Promos />
        <Popular />
      </div>
    </div>
  );
}

export default Home;
