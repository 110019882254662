import { useState } from 'react';

import Section from '../../elements/Section/Section';

import './Promt.css';



const promtAgree = localStorage.getItem('promtAgree') || false;

const setPromtAgree = (v = true) => {
  localStorage.setItem('promtAgree', v);
};

function Promt() {
  const [agree, setAgree] = useState(!!promtAgree);

  return agree ? null : (
    <div className="Promt">
      <div className="PromtDialog Card" role="alertdialog">
        <div className="Card-content">
          <div className="PromtText">
            <Section name="Agreement Popup" skeleton="Personal Data Agreement" />
          </div>
          <button
            type="button"
            className="PromtAgree"
            tabIndex={1}
            onClick={() => {
              setAgree(true);
              setPromtAgree();
            }}
          >
            Concordo
          </button>
        </div>
      </div>
    </div>
  );
}

export default Promt;
