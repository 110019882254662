import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { randomHlsColor } from '../../../helper';

import { getRecordsWithMeta } from '../../../requests';

import { add as addMessage } from '../../../state/notifySlice';

import Container from '../../elements/Container/Container';
import Fetching from '../../elements/Fetching/Fetching';
import Markdown from '../../elements/Markdown/Markdown';

import './Reviews.css';



const STAR = (
  <svg width="14" height="13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.56.814a.5.5 0 0 1 .88 0l1.81 3.35 3.797.64a.5.5 0 0 1 .271.846L10.64 8.341l.55 3.728a.5.5 0 0 1-.712.524L7 10.923l-3.478 1.67a.5.5 0 0 1-.711-.524l.549-3.728-2.678-2.69a.5.5 0 0 1 .27-.846l3.798-.642L6.56.814Z" fill="currentColor"/>
  </svg>
);

function ReviewRating ({ value }) {
  const count = Number(value) || 0;
  return count && (
    <div className="ReviewRating" title={`${count} stars`}>
      {Array(count).fill(STAR)}
    </div>
  );
}

function ReviewsListItem({ item, index }) {
  const author = item.Meta['Author'] || 'Anonymous';

  const rating = item.Meta['Rating'];

  const date = item.Title;

  const backgroundColor = randomHlsColor(50);

  return (
    <div className="Review Card space-one appear-seq" style={{ '--animation-order': index }}>
      <div className="flex flex-gap-half align-center">
        <div className="ReviewAuthorCircle" style={{ backgroundColor }}>
          {author.charAt(0)}
        </div>
        <div>
          <div className="ReviewAuthor">{author}</div>
          <div className="ReviewDate">{date}</div>
          <ReviewRating value={rating} />
        </div>
      </div>
      <Markdown className="ReviewContent">{item.Content || ''}</Markdown>
    </div>
  );
}

function ReviewsList({ items }) {
  return items.length > 0 ? (
    <div className="ReviewsList flex flex-gap flex-column">
      {items.map((item, i) => <ReviewsListItem key={item.ID} item={item} index={i} />)}
    </div>
  ) : null;
}

function Reviews() {
  const [fetching, setFetching] = useState(false);
  const [items, setItems] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    let disposed = false;
    setFetching(true);
    getRecordsWithMeta({ type: 'review' })
      .then(({ data }) => {
        if (!disposed) {
          const records = data || [];
          const sorted = records.sort((a, b) => a.Created < b.Created);
          setItems(sorted);
        }
      })
      .catch(error => {
        console.error(error);
        if (!disposed) dispatch(addMessage({
          type: 'error',
          text: 'Erro ao carregar "reviews"'
        }));
      })
      .finally(() => { if (!disposed) setFetching(false); });
    return () => disposed = true;
  }, [dispatch]);

  return (
  <div className="space-two">
    <Container>
      <div className="Reviews">
        {fetching ? <Fetching /> : <ReviewsList items={items} />}
      </div>
    </Container>
  </div>
  );

}

export default Reviews;
