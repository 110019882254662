import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { getOrder } from '../../../requests';
import { currency } from '../../../constants';

import { add as addMessage } from '../../../state/notifySlice';

import Fetching from '../../elements/Fetching/Fetching';
import Container from '../../elements/Container/Container';

import { OrderProduct } from './OrderPage';

import "./Print.css";



const REFRESH_INTERVAL = 90 * 1000;
const INTERVAL_VARY = 5 * 1000;

const randomInterval = (interval = 1000, vary = 100) => {
  return interval + Math.round(Math.random() * vary - vary / 2);
};

const dash = <span>&#8212;</span>;

function OrderInfo() {
  const { id, key } = useParams();
  const orderId = id ? Number(id) : null;
  const orderKey = key ? key : null;

  const [fetching, setFetching] = useState(false);
  const [order, setOrder] = useState({});
  
  const { isWorking } = useSelector(state => state.workingTime);

  const { isManager } = useSelector(state => state.settings);

  const intervalRef = useRef(null);

  const dispatch = useDispatch();

  const updateOrder = (id, key, check) => {
    if (check.disposed) return;
    setFetching(true);
    getOrder({ id, key })
      .then(({ data }) => {
        if (!check.disposed) setOrder(data || {});
      })
      .catch(error => {
        console.error(error);
        if (!check.disposed) dispatch(addMessage({
          type: 'error',
          text: 'Erro ao carregar "order"'
        }));
      })
      .finally(() => {
        if (!check.disposed) setFetching(false);
      });
  };

  const onPrintClick = () => {
    window.print();
  };

  useEffect(() => {
    const check = { disposed: false };
    clearInterval(intervalRef.current);
    intervalRef.current = null;
    if (orderId) {
      const update = () => updateOrder(orderId, orderKey, check);
      if (isWorking) intervalRef.current = setInterval(update, randomInterval(REFRESH_INTERVAL, INTERVAL_VARY));
      update();
    } else setOrder({});
    return () => {
      check.disposed = true;
      clearInterval(intervalRef.current);
    };
  }, [orderId, orderKey, isWorking]);

  const products = order['Content'] ? JSON.parse(order['Content']) : [];
  const meta = order['Meta'] || {};
  const address = meta['Address'] ? JSON.parse(meta['Address']) : {};
  const promos = meta['Promos'] ? JSON.parse(meta['Promos']) : [];
  const delivery = meta['Delivery'] ? !!Number(meta['Delivery']) : false;
  const restaurant = meta['Restaurant'] ? !!Number(meta['Restaurant']) : false;

  const addressLine = `${address.street||'_'}, ${address.building||'_'}, ${address.apartment||'_'}, ${address.entrance||'_'}, ${address.floor||'_'}`;

  const deliveryCost = delivery ? (
    meta['DeliveryCost'] ? Number(meta['DeliveryCost']) || 0 : 0
  ) : 0;

  const superTotal = Number(meta['Total']) + deliveryCost;

  return (
    <div className="Order space-two">
      <Container>
        {fetching ? <Fetching /> : (
          <div>
            <div className="OrderSection-title">
              Pedido {restaurant && 'no restaurante'} <span>{restaurant ? orderId : order['Title']}</span>
            </div>
            <p hidden={restaurant}><span className="OrderProp-title">Status:</span> <span className="badge">{meta['Status']}</span></p>
            <p><span className="OrderProp-title print-hide">Criação:</span> {order['Created']}</p>
            <p hidden={restaurant}><span className="OrderProp-title">Atualizada:</span> {order['Updated'] || '(nunca)'}</p>
            <div className="flex flex-wrap justify-between flex-gap">
              <div>
                <div className="OrderSection-title print-hide">Detalhes</div>
                <p><span className="OrderProp-title">Nome:</span> {meta['Name']}</p>
                <p hidden={restaurant}><span className="OrderProp-title">Telefone:</span> {meta['Phone'] || '(vazio)'}</p>
                <p hidden={restaurant}><span className="OrderProp-title">E-mail:</span> {meta['Email'] || '(vazio)'}</p>
                <p hidden={restaurant}><span className="OrderProp-title">Número contribuinte:</span> {meta['Tax'] || '(vazio)'}</p>
                <p hidden={restaurant}><span className="OrderProp-title">Hora {delivery ? 'receber' : 'levantar'}:</span> {meta['ReceiveTime']}</p>
                <p><span className="OrderProp-title">Comente:</span> {meta['Comment'] || dash}</p>
              </div>
              <div hidden={restaurant}>
                <div className="OrderSection-title">Entrega</div>
                <p><span className="OrderProp-title">Entrega:</span> {delivery ? 'Sim' : 'Não'}{!!deliveryCost && ` (${deliveryCost} ${currency})`}</p>
                <p><span className="OrderProp-title">Endereço:</span> {addressLine}</p>
                <p><span className="OrderProp-title">Pagamento:</span> {meta['Payment']}</p>
              </div>
              <div>
                <div className="OrderSection-title print-hide">Produtos</div>
                {products.map((item, index) => (
                  <OrderProduct item={item} />
                ))}
                <div className="OrderTotal-text"><span className="OrderProp-title">Total:</span> {superTotal} {currency}</div>
              </div>
              <div hidden={promos.length < 1}>
                <div className="OrderSection-title">Ofertas</div>
                {promos.length > 0 ? promos.map((item, index) => (
                  <div key={index} className="OrderProduct flex justify-between">
                    <div className="flex-ungrow">
                      <div className="OrderProduct-title">{item.product.Title}</div>
                      <div className="OrderProduct-variants">
                        {Object.keys(item.variants).map(prop => (<span key={prop}>{prop}: {item.variants[prop]}</span>))}
                      </div>
                    </div>
                  </div>
                )) : '(nenhum)'}
              </div>
            </div>
          </div>
        )}
        {isManager && !fetching && (
          <div className="space-one print-hide">
            <button type="button" className="button Order-PrintButton" onClick={onPrintClick}>
              Imprimir
            </button>
          </div>
        )}
      </Container>
    </div>
  );
}

export default OrderInfo;
