import { useSelector } from 'react-redux';

import Container from '../../elements/Container/Container';
import Fetching from '../../elements/Fetching/Fetching';
import Markdown from '../../elements/Markdown/Markdown';
import Image from '../../elements/Image/Image';

import './Promos.css';



function Promo({ item, index }) {
  return (
    <div
      className="Promo Card Card-big appear-seq"
      style={{ '--animation-order': index }}
    >
      <div className="Promo-image Card-image">
        <Image src={item.Image} loading="lazy" />
      </div>
      <div className="Card-content">
        <Markdown>{item.Content}</Markdown>
      </div>
    </div>
  );
}

function PromosList({ items }) {
  return items.length > 0 ? (
    <div className="PromosList flex flex-wrap flex-gap flex-equal flex-align-stretch">
      {items.map((item, i) => (
        <Promo key={item.ID} item={item} index={i} />
      ))}
    </div>
  ) : null;
}

function Promos() {
  const { promos: items, fetching } = useSelector(state => state.promos);

  return (
    <section className="space-one fade-in">
      <Container>
        {fetching && items.length === 0
          ? <Fetching />
          : <PromosList items={items} />
        }  
      </Container>
    </section>
  );
}

export default Promos;
