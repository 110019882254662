import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { developerEmail, developerName } from '../../../constants';

import Container from '../../elements/Container/Container';
import Section from '../../elements/Section/Section';

import './Footer.css';



function Footer({ ...rest }) {
  const today = new Date();
  const year = today.getFullYear();
  const showWho = today.getDate() === 26;

  const { isManager } = useSelector(state => state.settings);

  return (
    <footer className="Footer">
      <Container {...rest}>
        <div className="flex flex-wrap flex-gap justify-between">
          <Section className="Footer-section" name="Footer - Column 1" />
          <Section className="Footer-section" name="Footer - Column 2" />
          <Section className="Footer-section" name="Footer - Column 3" />
          <div className="Footer-section Footer-links">
            <p><Link to="/menu">Ementa</Link></p>
            <p><Link to="/info/">Informação</Link></p>
            <p><Link to="/order/my">Meus pedidos</Link></p>
            {isManager && (
              <p><Link to="/manager">Gestor modo</Link></p>
            )}
          </div>
        </div>
        <div className="Footer-end flex flex-wrap flex-gap align-center space-t">
          <div className="Footer-sign">
            © 2012–{year}, <span>PizzaTime &amp; Adorella</span>
          </div>
          <div hidden={!showWho} className="Footer-developer">
            Developed by <span title={developerEmail}>{developerName}</span>
          </div>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
