import { createSlice } from '@reduxjs/toolkit';



let pastOrders = localStorage.getItem('pastOrders');
pastOrders = pastOrders ? JSON.parse(pastOrders) : [];

export const orderSlice = createSlice({
  name: 'variants',
  initialState: {
    pastOrders,
  },
  reducers: {
    addPastOrder: (state, action) => {
      state.pastOrders.push(action.payload);
      localStorage.setItem(
        'pastOrders',
        JSON.stringify(state.pastOrders)
      );
    },
  },
});

export const { addPastOrder } = orderSlice.actions;

export default orderSlice.reducer;
