import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { scrollTop } from '../../../helper';

import Container from '../../elements/Container/Container';

import './PastOrders.css';



const TIME_DIFF = 1000 * 60 * 60 * 8;

const checkShow = (date) => {
  const orderDate = date ? new Date(date) : null;
  return orderDate && orderDate.getTime() > Date.now() - TIME_DIFF;
};

export const LastOrderWidget = () => {
  const location = useLocation();

  const { isManager } = useSelector(state => state.settings);

  const { pastOrders } = useSelector(state => state.order);

  const lastOrder = pastOrders && pastOrders.length > 0
    ? [...pastOrders].sort((a, b) => a.date - b.date)[pastOrders.length - 1] : null;

  const path = `/order/${lastOrder?.id}/${lastOrder?.key}`;

  const show = lastOrder && path !== location.pathname && checkShow(lastOrder.date);

  return !isManager && show && (
    <div className="LastOrder popup">
      <Link className="BigButton" to={path} onClick={scrollTop}>
        Pedido recente
      </Link>
    </div>
  );
};

const PastOrder = ({ item }) => {
  const { id, key, date } = item;

  const orderDate = new Date(date);

  const link = `/order/${id}/${key}`;

  return (
    <li className="PastOrder">
      <Link to={link}>
        {orderDate.toLocaleDateString()} - {orderDate.toLocaleTimeString()}
      </Link>
    </li>
  );
};

const PastOrders = () => {
  const { pastOrders } = useSelector(state => state.order);

  return (
    <div className="PastOrders space-one">
      <Container>
        <h2>Meus pedidos</h2>
        {pastOrders?.length ? (
          <ol className="PastOrders-list">
            {pastOrders.map((item) => (
              <PastOrder key={item.id} item={item} />
            ))}
          </ol>
        ) : (
          <h6>(nenhum pedido na lista)</h6>
        )}
        <p className="PastOrders-disclaimer">* Esta lista consiste em pedidos feitos neste dispositivo e nele armazenados apenas</p>
      </Container>
    </div>
  );
};

export default PastOrders;
