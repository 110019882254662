import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { setSettings, setSettingsFetching } from './state/settingsSlice';
import { setSections, setSectionsFetching } from './state/sectionsSlice';
import { setCategories, setCategoriesFetching } from './state/categoriesSlice';
import { setProducts, setProductsFetching } from './state/productsSlice';
import { setAllOptions, setOptionsFetching } from './state/optionsSlice';
import { setPromos, setPromosFetching } from './state/promosSlice';
import { add as addMessage } from './state/notifySlice';

import { getCategories, getProductOptions, getProducts, getPromos, getSections, getSettings } from './requests';

import Header from './template/components/Header/Header';
import Footer from './template/components/Footer/Footer';
import CustomStyle from './template/components/Style/Style';
import PastOrders, { LastOrderWidget } from './template/components/PastOrders/PastOrders';
import Notifications from './template/components/Notifications/Notifications';
import WorkingTime from './template/components/WorkingTime/WorkingTime';
import Promt from './template/components/Promt/Promt';
import Cart from './template/components/Cart/Cart';

import Home from './template/layouts/Home/Home';
import Products from './template/layouts/Products/Products';
import Promos from './template/layouts/Promos/Promos';
import Records from './template/layouts/Records/Records';
import Record from './template/layouts/Records/Record';
import Gallery from './template/layouts/Gallery/Gallery';
import Reviews from './template/layouts/Reviews/Reviews';
import OrderPage from './template/layouts/Order/OrderPage';
import OrderInfo from './template/layouts/Order/OrderInfo';
import Manager from './template/layouts/Manager/Manager';

import './template/icons.css';
import './template/elements/Card/Card.css';

import store from "./state/store";





store.dispatch(setSettingsFetching(true));
getSettings()
  .then(({ data }) => { store.dispatch(setSettings(data)); })
  .catch(error => {
    console.error(error);
    store.dispatch(addMessage({
      type: 'error',
      text: 'Erro ao carregar "settings"'
    }));
  })
  .finally(() => { store.dispatch(setSettingsFetching(false)); });
  
store.dispatch(setSectionsFetching(true));
getSections()
  .then(({ data }) => { store.dispatch(setSections(data)); })
  .catch(error => {
    console.error(error);
    store.dispatch(addMessage({
      type: 'error',
      text: 'Erro ao carregar "sections"'
    }));
  })
  .finally(() => { store.dispatch(setSectionsFetching(false)); });

store.dispatch(setPromosFetching(true));
getPromos()
  .then(({ data }) => {
    const items = data || [];
    const mapped = items.map(item => (
      { ...item, 'Conditions': JSON.parse(item['Meta']['Conditions'] || '{}') }
    ));
    store.dispatch(setPromos(mapped));
  })
  .catch(error => {
    console.error(error);
    store.dispatch(addMessage({
      type: 'error',
      text: 'Erro ao carregar "promos"'
    }));
  })
  .finally(() => { store.dispatch(setPromosFetching(false)); });

store.dispatch(setProductsFetching(true));
getProducts()
  .then(({ data }) => { store.dispatch(setProducts(data)); })
  .catch(error => {
    console.error(error);
    store.dispatch(addMessage({
      type: 'error',
      text: 'Erro ao carregar "products"'
    }));
  })
  .finally(() => { store.dispatch(setProductsFetching(false)); });

store.dispatch(setOptionsFetching(true));
getProductOptions()
  .then(({ data }) => { store.dispatch(setAllOptions(data || [])); })
  .catch(error => {
    console.error(error);
    store.dispatch(addMessage({
      type: 'error',
      text: 'Erro ao carregar "product options"'
    }));
  })
  .finally(() => { store.dispatch(setOptionsFetching(false)); });

function App() {
  const { settings } = useSelector(state => state.settings);

  const productsMenuId = settings['Products Menu ID'] || '';

  const dispatch = useDispatch();

  useEffect(() => {
    let disposed = false;

    if (productsMenuId) {
      dispatch(setCategoriesFetching(true));
      getCategories(productsMenuId)
        .then(({ data }) => { if (!disposed) dispatch(setCategories(data)); })
        .catch(error => {
          console.error(error);
          if (!disposed) dispatch(addMessage({
            type: 'error',
            text: 'Erro ao carregar "categories"'
          }));
        })
        .finally(() => { if (!disposed) dispatch(setCategoriesFetching(false)); });
    }

    return () => { disposed = true };
  }, [dispatch, productsMenuId]);

  return (
    <BrowserRouter>
      <Header />
      <WorkingTime />
      <main id="content" className="bg-default" role="main">
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/record/:id" element={<Records />} />
          <Route path="/menu" exact element={<Products />} />
          <Route path="/menu/:id" element={<Products />} />
          <Route path="/promos" exact element={<Promos />} />
          <Route path="/promo/:id" element={<Promos />} />
          <Route path="/order" exact element={<OrderPage />} />
          <Route path="/order/my" element={<PastOrders />} />
          <Route path="/order/:id/:key" element={<OrderInfo />} />
          <Route path="/info" exact element={<Records />} />
          <Route path="/info/:id" element={<Record />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/reviews" element={<Reviews />} />
          <Route path="/manager" element={<Manager />} />
        </Routes>
      </main>
      <Footer />
      <Cart />
      <LastOrderWidget />
      <Promt />
      <Notifications />
      <CustomStyle />
    </BrowserRouter>
  );
}

export default App;
