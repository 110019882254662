import { cls } from "../helper";

import './Icon.css';



export const Icon = ({ className, primary = false, space, children, ...rest }) => {
  const classes = cls(
    'Icon',
    primary && 'Icon--primary',
    space && `Icon--space-${space}`,
    className,
  );

  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
};
