import { configureStore } from '@reduxjs/toolkit';

import notifyReducer from './notifySlice';
import settingsReducer from './settingsSlice';
import sectionsReducer from './sectionsSlice';
import categoriesReducer from './categoriesSlice';
import workingTimeReducer from './workingTimeSlice';
import productsReducer from './productsSlice';
import variantsReducer from './variantsSlice';
import optionsReducer from './optionsSlice';
import promosReducer from './promosSlice';
import orderReducer from './orderSlice';
import cartReducer from './cartSlice';

export default configureStore({
  reducer: {
    notify: notifyReducer,
    settings: settingsReducer,
    sections: sectionsReducer,
    categories: categoriesReducer,
    workingTime: workingTimeReducer,
    products: productsReducer,
    variants: variantsReducer,
    options: optionsReducer,
    promos: promosReducer,
    order: orderReducer,
    cart: cartReducer,
  }
});
