import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { currency } from '../../../constants';

import { cls, scrollTop } from '../../../helper';

import { add as addMessage } from '../../../state/notifySlice';
import { addItem, save } from '../../../state/cartSlice';

import {
  setCurrentProduct as setCurrentProductForOptions,
  setSelectedOptions
} from '../../../state/optionsSlice';

import {
  setCurrent as setCurrentProductForVariants,
  setVariants
} from '../../../state/variantsSlice';

import Container from '../../elements/Container/Container';
import Fetching from '../../elements/Fetching/Fetching';
import Image from '../../elements/Image/Image';

import './Products.css';
import { CartIcon } from '../../../icons/CartIcon';
import { Icon } from '../../../icons/Icon';



const VARIANTS_SELECTOR_FROM = 3;

export const PRODUCT_PLACEHOLDER = '/images/placeholder.svg';

const ProductSearch = ({ value, onValueChange }) => {
  const handleValueChange = (event) => {
    scrollTop();
    onValueChange(event.target.value);
  };

  const handleClear = () => {
    onValueChange('');
  };

  return (
    <div className="ProductSearch">
      <Container>
        <div className="ProductSearchForm">
          <input
            type="text"
            className="input-field input-field-narrow"
            value={value}
            onChange={handleValueChange}
            placeholder="Pesquisar produtos por título"
            autoFocus
          />
          <button
            type="button"
            disabled={!value}
            onClick={handleClear}
            className="button"
          >
            &times;
          </button>
        </div>
      </Container>
    </div>
  )
};

export const Product = ({ item, index }) => {
  const [quan, setQuan] = useState(1);

  const id = item ? item.ID : 0;

  const { variants } = useSelector(state => (state.variants));
  const { selected: options } = useSelector(state => (state.options));

  const selectedVariants = variants[id] || [];
  const selectedOptions = options[id] || [];

  const selectedOptionsCount = selectedOptions.reduce((acc, o) => acc + o.quantity, 0);

  const availableVariants = item.Meta && item.Meta['Variants']
    ? JSON.parse(item.Meta['Variants']) || {} : {};
  const availableOptions = item.Meta && item.Meta['Options']
    ? JSON.parse(item.Meta['Options']) || [] : [];

  const variantNames = Object.keys(availableVariants);

  const showVariants = variantNames.length > 0;
  const showOptions = availableOptions.length > 0;

  const dispatch = useDispatch();

  const plus = () => {
    setQuan(quan + 1);
  };

  const minus = () => {
    if (quan > 1) setQuan(quan - 1);
  };

  const setVari = (selected) => {
    dispatch(setVariants({ productId: id, selected }));
  };

  const defaultVariants = () => {
    const vari = {};
    variantNames.forEach(v => {
      const names = Object.keys(availableVariants[v]);
      if (names) vari[v] = names[0];
    });
    setVari(vari);
  };

  const content = item.Content ? item.Content.replaceAll(',',', ').trim() : '';

  useEffect(() => {
    defaultVariants();
  }, []);

  const price = () => {
    let price = Number(item.Meta['Price']) || 0;
    let variantsPrice = 0;
    Object.keys(selectedVariants).forEach(v => {
      if (availableVariants[v])
        variantsPrice += Number(availableVariants[v][selectedVariants[v]]) || 0;
    });
    let optionsPrice = 0;
    selectedOptions.forEach(s => {
      let optionPrice = Number(s.option.Meta['Price']) || 0;
      const optionPrices = s.option.Meta['Variants']
        ? JSON.parse(s.option.Meta['Variants']) || {} : {};
      Object.keys(optionPrices).forEach(v => {
        optionPrice = Number(optionPrices[v][selectedVariants[v]]) || 0;
      });
      optionsPrice += optionPrice * s.quantity;
    });
    price = Math.max(price, variantsPrice) + optionsPrice;
    return Math.round(price * 100) / 100;
  }

  const cartItem = () => ({
    product: item,
    quantity: quan,
    variants: selectedVariants,
    options: selectedOptions,
    price: price(),
  });

  const toCart = () => {
    dispatch(addItem(cartItem()));
    dispatch(save());
    dispatch(setSelectedOptions({ productId: id, selected: [] }));
    dispatch(addMessage({type: 'info', text: 'Produto adicionado ao carrinho' }));
  };

  return (
    <div className="Product appear-seq" style={{ '--animation-order': index }}>
      <div className="flex flex-gap full-height">
        <div className="ProductImage flex-unshrink">
          <Image src={item.Image} loading={index<4?'eager':'lazy'} placeholder={PRODUCT_PLACEHOLDER} />
        </div>
        <div className="flex-grow flex flex-column flex-align-stretch">
          <div className="flex justify-between">
            <div className="ProductTitle">{item.Title}</div>
            <div className="ProductPrice">{currency}{price()}</div>
          </div>
          <div className="ProductDescription flex-grow">{content || ' '}</div>
          <div className="flex flex-gap-half justify-between align-end flex-wrap">
            {showVariants ? (
              <div className="ProductVariants">
                {variantNames.map(vari => {
                  const values = Object.keys(availableVariants[vari]);
                  return (
                    <div className="ProductVariant" key={vari}>
                      {values.length > VARIANTS_SELECTOR_FROM ? (
                        <button
                          type="button"
                          className="ProductVariantSelectOpen"
                          onClick={() => dispatch(setCurrentProductForVariants(item))}
                        >
                          {vari && <span>{vari}: </span>}
                          <span>{selectedVariants[vari]}</span>
                        </button>
                      ) : (<>
                        {!!vari && variantNames.length > 1 && (
                          <span className="ProductVariantsTitle">
                            {vari}
                          </span>
                        )}
                        {values.map(val => (
                          <button
                            key={val}
                            type="button"
                            className={`ProductVariantValue${selectedVariants[vari]!==val ? '' : ' current'}`}
                            onClick={() => setVari({...selectedVariants, [vari]: val })}
                          >
                            <span>{val}</span>
                          </button>
                        ))}
                      </>)}
                    </div>
                  )
                })}
              </div>
            ):(
              <div className="ProductQuantity">
                <button disabled={quan === 1} type="button" className="ProductQuantityButton" onClick={minus} title="Diminuir contagem">
                  -
                </button>
                <span className="ProductQuantityNumber">{quan}</span>
                <button type="button" className="ProductQuantityButton" onClick={plus} title="Aumentar contagem">
                  +
                </button>
              </div>
            )}
            {showOptions && (
              <div className="ProductOptions">
                <button
                  type="button"
                  className={cls('ProductOptionsSelectOpen',selectedOptions.length > 0 && 'full')}
                  onClick={() => dispatch(setCurrentProductForOptions({ product: item, variants: selectedVariants }))}
                >
                  {selectedOptions.length > 0 ? (<span>
                    {selectedOptionsCount}
                    &nbsp;opções
                  </span>) : 'Opções'}
                </button>
                {/*selectedOptions.length > 0 && (
                  <div hidden>
                    {selectedOptions.map(s => (
                      <span className="ProductOption" key={s.option.ID}>
                        {s.option.Title} &times; {s.quantity}
                      </span>
                    ))}
                  </div>
                )*/}
              </div>
            )}
            <button
              type="button"
              className="ProductAddToCart"
              onClick={toCart}
              title="Adicionar ao carrinho"
            >
              <Icon>
                <CartIcon />
              </Icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ProductsList = ({ items }) => {
  return items.length > 0 ? (
    <div className="ProductsList">
      {items.map((item, i) => (
        <Product key={item.ID} item={item} index={i} />
      ))}
    </div>
  ) : 'Nenhum item para mostrar';
};

export const ProductVariants = () => {
  const { variants, current } = useSelector(state => state.variants);
  
  const productId = current ? current.ID || 0 : 0;

  const selected = variants[productId] || [];

  const available = current && current.Meta && current.Meta['Variants']
    ? JSON.parse(current.Meta['Variants']) || {} : {};

  const dispatch = useDispatch();

  const setVari = (selected) => {
    dispatch(setVariants({ productId, selected }));
  };

  const close = () => dispatch(setCurrentProductForVariants(null));

  return productId !== 0 && (
    <div className="ProductVariantsSelect-wrap" onClick={close}>
      <div className="ProductVariantsSelect" onClick={e => e.stopPropagation()}>
        <div className="ProductOptionsSelectTitle">
          <span>Escolha variantes</span>
          <button
            type="button"
            className="ProductOptionsSelectClose"
            onClick={close}
          >
            ×
          </button>
        </div>
        <div className="ProductVariantsList">
          {Object.keys(available).map(prop =>  (
            <div className="ProductVariantsListItem" key={prop}>
              <span className="ProductVariantsListItemTitle">{prop}: </span>
              {Object.keys(available[prop]).map(item => (
                <button
                  key={item}
                  type="button"
                  className={`ProductVariantsListSubitem${selected[prop]!==item ? '' : ' current'}`}
                  onClick={() => setVari({...selected, [prop]: item })}
                >
                  {item}
                </button>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const getOptionPrice = (option, variants) => {
  let optionPrice = Number(option.Meta['Price']) || 0;
  const optionPrices = option.Meta['Variants'] ? JSON.parse(option.Meta['Variants']) || {} : {};
  Object.keys(optionPrices).forEach(v => {
    optionPrice = Number(optionPrices[v][variants[v]]) || 0;
  });
  return optionPrice;
};

export const ProductOptions = () => {
  const {
    fetching,
    all: allOptions,
    selected: options,
    currentProduct: current,
    currentProductVariants: variants
  } = useSelector(state => state.options);

  const productId = current ? current.ID || 0 : 0;

  const avbailable = current && current.Meta && current.Meta['Options']
    ? JSON.parse(current.Meta['Options']) || [] : [];

  const selected = options[productId] || [];

  const dispatch = useDispatch();

  const addOption = (option, add = true) => {
    const same = selected.find(s => s.option.ID === option.ID);
    let newValue = null;
    if (!same) {
      const newOption = { option, quantity: 1 };
      newValue = [...selected, newOption];
    } else {
      newValue = [...selected];
      const newQuantity = same.quantity + (add ? 1 : -1);
      const del = newValue.indexOf(same);
      if (del >= 0) {
        if (newQuantity > 0)
          newValue.splice(del, 1, { option, quantity: newQuantity });
        else newValue.splice(del, 1);
      }
    }
    dispatch(setSelectedOptions({ productId, selected: newValue }));
  }

  const close = () => dispatch(setCurrentProductForOptions({}));
  
  return productId !== 0 && avbailable.length > 0 && (
    <div className="ProductOptionsSelect-wrap" onClick={close}>
      <div className="ProductOptionsSelect" onClick={e => e.stopPropagation()}>
        <div className="ProductOptionsSelectTitle">
          <span>Adicionar opções</span>
          <button
            type="button"
            className="ProductOptionsSelectClose"
            onClick={close}
          >
            ×
          </button>
        </div>
        <div className="ProductOptionsSelected">
          <div className="ProductOptionsSelectedTitle">
            Opções adicionadas
            ({Math.round(selected.reduce((acc, s) => acc + getOptionPrice(s.option, variants) * s.quantity, 0) * 100) / 100} {currency})
          </div>
          <div className="ProductOptionsSelectedList">
            {selected.length > 0 ? selected.map(s => (
              <div key={s.option.ID} className="ProductOptionsSelectedItem">
                <button type="button" className="ProductOptionsDecrease" onClick={() => addOption(s.option, false)}>
                  {s.quantity > 1 ? '-' : '×'}
                </button>
                <div className="ProductOptionsItemTitle">{s.option.Title} × {s.quantity}</div>
                <button type="button" className="ProductOptionsIncrease" onClick={() => addOption(s.option)}>
                  +
                </button>
              </div>
            )) : (
              <div className="ProductOptionsSelectedEmpty">nenhum</div>
            )}
          </div>
        </div>
        <div className="ProductOptionsList">
          {fetching && allOptions.length === 0 ? <Fetching /> : (
            allOptions.filter(o => avbailable.find(a => a === o.ID) !== undefined)
              .sort((a, b) => a.Content > b.Content ? 1 : (a.Content === b.Content ? 0 : -1))
              .map(option => (
                <button
                  type="button"
                  key={option.ID}
                  title={option.Content}
                  className={`ProductOptionsListItem${selected.find(s => s.option.ID === option.ID) ? ' selected' : ''}`}
                  onClick={() => addOption(option)}
                >
                  <span>{option.Title}</span>
                  <span>{getOptionPrice(option, variants)} {currency}</span>
                </button>
              )
            ))}
        </div>
      </div>
    </div>
  );
};

const Products = () => {
  const [search, setSearch] = useState('');

  const { categories: cats, fetching } = useSelector(state => state.categories);

  const { items: products, fetching: productsFetching } = useSelector(state => state.products);

  const { isManager } = useSelector(state => state.settings);

  const { id } = useParams();

  const category = id ? Number(id) : null;

  const filtered = category ? products.filter(item => item['ParentID'] === category) : [...products];

  const query = search.trim().toLowerCase()
  const searched = query ? filtered.filter(item => item?.Title?.toLowerCase().includes(query)) : filtered;

  const sorted = searched
    .sort((a, b) => {
      const an = parseInt(a.Title.replace(/,/g,''));
      const bn = parseInt(b.Title.replace(/,/g,''));
      if (isNaN(an) && isNaN(bn))
        return a.Title > b.Title ? 1 : (a.Title === b.Title ? 0 : -1);
      return isNaN(an) ? -1 : (isNaN(bn) ? 1 : an - bn);
    })
    .sort((a, b) => a.ParentID - b.ParentID);

  return (
    <div>
      <Container>
        <div className="ProductCategories-wrap">
          <div className="ProductCategories" role="menubar">
            <Link
              key={0}
              to={'/menu'}
              className={`ProductCategory${!category?' current':''} appear-seq`}
              role="menuitem"
              onClick={scrollTop}
            >
              Todas
            </Link>
            {cats.map((item, i) => (
              <Link
                key={item.ID}
                to={`/menu/${item.ID}`}
                role="menuitem"
                className={`ProductCategory${category!==item.ID?'':' current'} appear-seq`}
                style={{ '--animation-order': i+1 }}
                onClick={scrollTop}
              >
                {item.Title}
              </Link>
            ))}
          </div>
        </div>
        <div className="ProductsList-wrap">
          {productsFetching || fetching ?
            <Fetching /> :
            <ProductsList items={sorted} />
          }
        </div>
      </Container>
      <ProductVariants />
      <ProductOptions />
      {isManager && (
        <ProductSearch value={search} onValueChange={setSearch} />
      )}
    </div>
  );
};

export default Products;
