import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { currency } from '../../../constants';

import { toggle } from '../../../state/cartSlice';

import { CartIcon } from '../../../icons/CartIcon';
import { Icon } from '../../../icons/Icon';

import './MiniCart.css';



export function MiniCart() {
  const { quantity, total, opened } = useSelector((state) => state.cart);

  const dispatch = useDispatch();

  const [wasOpened, setWasOpened] = useState(false);
  
  const cartOpenRef = useRef(null);
  
  useEffect(() => {
    if (!wasOpened && opened) { setWasOpened(true) }
    if (wasOpened && !opened && cartOpenRef.current) {
      setTimeout(() => cartOpenRef.current.focus(), 100);
    }
  }, [opened, wasOpened]);

  return (
    <div className="MiniCart flex-unshrink">
      <button
        ref={cartOpenRef}
        title="Abrir carrinho"
        className="MiniCart-Button button"
        onClick={() => dispatch(toggle())}
      >
        {!!total && (
          <div className="MiniCart-Button-Total">
            {Math.round(total * 100) / 100} {currency}
          </div>
        )}
        <Icon>
          <CartIcon />
        </Icon>
        {!!quantity && (
          <div className="MiniCart-Button-Quantity">
            {quantity}
          </div>
        )}
      </button>
    </div>
  );
}
