import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { getRecordByID } from '../../../requests';

import { add as addMessage } from '../../../state/notifySlice';

import Container from '../../elements/Container/Container';
import Fetching from '../../elements/Fetching/Fetching';
import Markdown from '../../elements/Markdown/Markdown';

import './Records.css';

function RecordBody({ record }) {
  return (
    <article className="Record Card space-one appear-seq">
      <h2 className="RecordTitle">{record.Title}</h2>
      <Markdown className="RecordContent">{record.Content || ''}</Markdown>
    </article>
  );
}

function Record() {
  const [fetching, setFetching] = useState(false);
  const [record, setRecord] = useState({});

  const dispatch = useDispatch();

  const { id } = useParams();

  const recordId = id ? Number(id) : null;

  useEffect(() => {
    let disposed = false;
    setFetching(true);
    getRecordByID({ id: recordId })
      .then(({ data }) => { if (!disposed) setRecord(data || {}); })
      .catch(error => {
        console.error(error);
        if (!disposed) dispatch(addMessage({
          type: 'error',
          text: 'Erro ao carregar conteúdo'
        }));
      })
      .finally(() => { if (!disposed) setFetching(false); });
    return () => disposed = true;
  }, [recordId, dispatch]);

  return (
  <div className="RecordWrap">
    <Container>
      <div className="space-two">
        {fetching ? <Fetching /> : <RecordBody record={record} />}
      </div>
    </Container>
  </div>
  );

}

export default Record;
